import React, { useState } from "react";
import "../styles/App.css";
import AppRouter from "../routes/AppRouter";

function Header() {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className="App-header">
      <button
        onClick={toggleNav}
        className="hamburger-btn"
        title="Navigation Menu - Click to Open | Close"
      >
        ☰
      </button>
      <h1 className="mainDashHeader" title="Full-Stack Engineer">
        David Wright
      </h1>
      <AppRouter isNavVisible={isNavVisible} toggleNav={toggleNav} />
    </header>
  );
}

export default Header;
