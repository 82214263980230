import React from "react";
import { Link, Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Dashboard from "../components/Dashboard";
import Projects from "../components/projects";
import Contact from "../components/contact";
import PrivacyPolicy from "../components/privacyPolicy";

function AppRouter({ toggleNav, isNavVisible }) {
  return (
    <div className="routerNav">
      <Router>
        <nav className={`navLinks ${isNavVisible ? "visible" : "hidden"}`}>
          <Link to="/" onClick={toggleNav}>
            Home
          </Link>

          <Link to="/projects" onClick={toggleNav}>
            My Work
          </Link>

          <Link to="/contact" onClick={toggleNav}>
            Contact Me
          </Link>

          <Link to="/privacyPolicy" onClick={toggleNav}>
            Privacy Policy
          </Link>

          <button onClick={toggleNav} className="closeNavButton">
            X
          </button>
        </nav>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default AppRouter;
